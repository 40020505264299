<template>
  <div>
    <Modal title="短时降雨" :visible="visible" @close="visible = false">
      <Rain />
    </Modal>
  </div>
</template>


<script>
import Rain from "./components/rain.vue";
export default {
  components: {
    Rain,
  },

  data() {
    return {
      visible: true,
      updateTime: "",
      weatherList: [],
    };
  },
};
</script>


<style lang="less" scoped>
.wrapper {
  height: 80vh;
  overflow-y: auto;
}
.grid-panel {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.title {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #353744;
  display: flex;
  align-items: center;
  position: relative;
  left: 1em;
  &::before {
    content: "";
    position: absolute;
    top: 0.5em;
    bottom: 0.3em;
    left: -1em;
    width: 6px;
    background-color: var(--theme-color);
  }
}

.time {
  font-size: 12px;
  padding-left: 34px;
}
.hours {
  height: 270px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  background-color: rgba(111, 131, 239, 0.1);
  padding: 0 20px;
  font-size: 12px;
  font-weight: 400;
  & > div {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hour {
    border-top: 1px solid var(--theme-color);
    padding-top: 12px;
    color: var(--theme-color);
  }

  .active {
    background-color: var(--theme-color);
    color: #fff;

    .hour {
      border-top: 1px solid #fff;
      color: #fff;
    }
  }
}

.weather {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: rgba(111, 131, 239, 0.1);

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-wrapper {
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
    }

    .quality {
      background-color: var(--theme-color);
      padding: 0 8px;
      line-height: 1.5em;
      border-radius: 24px;
      font-size: 12px;
      color: #fff;
    }
  }
}
</style>
