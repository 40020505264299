<template>
  <div v-if="list.length > 0">
    <Modal
      title="卫星云图"
      :visible="visible"
      @close="visible = false"
      width="60vw"
    >
      <div class="data">
        <div class="time">{{ activeInfo.time }}</div>
        <img :src="activeInfo.url" style="width: 100%" alt="" />
        <img
          src="@/assets/play.png"
          class="play"
          alt=""
          @click="play"
          v-if="!isPlay"
        />
        <img
          src="@/assets/stop-play.png"
          class="play"
          alt=""
          @click="play"
          v-else
        />
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: true,

      list: [],
      index: 0,
      activeInfo: {}, // 正在播放的云图
      isPlay: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      fetch(
        "https://adm.ac.cn/Api/Weather/getSatellite?token=njszy&ch=FY4A-true-color"
      )
        .then((res) => res.json())
        .then((res) => {
          console.log("res", res);
          if (res && Array.isArray(res.info)) {
            this.list = res.info;
            this.activeInfo = res.info[0];
          }
        });
    },

    play() {
      if (this.timer) {
        this.isPlay = false;
        clearInterval(this.timer);
        this.timer = null;
        this.index = 0;
      } else {
        this.isPlay = true;
        this.setTimer();
      }
    },

    setTimer() {
      this.timer = setInterval(() => {
        if (this.index === this.list.length - 1) {
          console.log("stop play");
          this.play(); // 停止播放
        } else {
          this.activeInfo = this.list[this.index + 1];
          this.index += 1;
        }
      }, 1000);
      this.$once("hook:beforeDestroy", () => {
        if (this.timer) {
          console.log("清空计时器");
          clearInterval(this.timer);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.data {
  position: relative;

  .time {
    position: absolute;
    top: 4vh;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
  }

  .play {
    position: absolute;
    bottom: 4vh;
    left: 50%;
    transform: translateX(-50%);
    width: 3vw;
    cursor: pointer;
  }
}
</style>