<template>
  <div>
    <div class="selector">
      <div class="header">气象信息</div>

      <div class="section">
        <div class="item" href="#" @click.prevent="active = 'weather'">
          <template v-if="active === 'weather'">
            <div class="active">天气预报</div>
          </template>
          <template v-else>
            <div class="">天气预报</div>
          </template>
        </div>
        <div class="item" href="#" @click.prevent="active = 'rain'">
          <template v-if="active === 'rain'">
            <div class="active">短时降雨</div>
          </template>
          <template v-else>
            <div class="">短时降雨</div>
          </template>
        </div>
        <div class="item" href="#" @click.prevent="active = 'cloud'">
          <template v-if="active === 'cloud'">
            <div class="active">卫星云图</div>
          </template>
          <template v-else>
            <div class="">卫星云图</div>
          </template>
        </div>
        <div class="item" href="#" @click.prevent="active = 'radar'">
          <template v-if="active === 'radar'">
            <div class="active">雷达信息</div>
          </template>
          <template v-else>
            <div class="">雷达信息</div>
          </template>
        </div>
      </div>
    </div>
    <Weather v-if="active === 'weather'" />
    <Cloud v-if="active === 'cloud'" />
    <Rain v-if="active === 'rain'" />
    <Radar v-if="active === 'radar'" />
  </div>
</template>

<script>
import Cloud from "./components/cloud";
import Rain from "./components/rain";
import Radar from "./components/radar";
import Weather from "./components/weather";
export default {
  components: {
    Cloud,
    Rain,
    Weather,
    Radar,
  },
  data() {
    return {
      active: "weather",
    };
  },
};
</script>



<style lang="less" scoped>
.selector {
  position: fixed;
  top: 10vh;
  left: 1vw;
  z-index: 99;
  background-color: var(--bg-color);

  width: 15vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .section {
    padding: 2vh 1vw 0;
    border-bottom: 1px solid #e2e2e2;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 1.8vh;
      cursor: pointer;

      .inner {
        display: flex;
        align-items: center;
        color: var(--text-color);
      }
      img {
        width: 1vw;
        margin-right: 0.4vw;
      }
    }

    .active {
      color: var(--theme-color);
    }
  }
}
</style>