<template>
  <div>
    <Modal
      :title="activeCity.name + '雷达信息'"
      :visible="visible"
      @close="visible = false"
      width="60vw"
    >
      <!-- <div class="category">
        <template v-for="(item, index) in categoryList">
          <div
            :key="item.name"
            :class="activeCity.city === item.city ? 'active' : ''"
            @click="activeCity = item"
          >
            {{ item.name }}
          </div>
          <span
            v-if="index !== categoryList.length - 1"
            :key="item.city"
            style="padding: 0 4px"
            >|</span
          >
        </template>
      </div> -->

      <div v-if="list.length > 0" class="radar">
        <img
          class="image"
          v-if="list[activeIndex]"
          :src="list[activeIndex].url"
        />

        <div class="footer">
          <div class="" @click="reduceActiveIndex">
            <i class="iconfont icon-left-circle"></i>
          </div>
          <div class="" v-if="!isTime" @click="setTimer">
            <i class="iconfont icon-play-circle"></i>
          </div>
          <div class="" v-if="isTime" @click="stopTimer">
            <i class="iconfont icon-timeout"></i>
          </div>
          <div class="" @click="setActiveIndex">
            <i class="iconfont icon-right-circle"></i>
          </div>

          <div
            v-for="(item, index) in list.length"
            :key="item.url"
            :class="activeIndex === index ? 'active' : ''"
            @click="setActiveIndex(index)"
          ></div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
function fetchRadar() {
  // return fetch(
  //   "http://adm.ac.cn/api/meteor/getRadar?ch=huazhong&token=yzqxj"
  // ).then((res) => res.json());
  return fetch(
    `https://adm.ac.cn/api/weather/getRadar?token=njszy&ch=huazhong`
  ).then((res) => res.json());
}

function fetchCityRadar({ city }) {
  return fetch(
    `http://adm.ac.cn/api/meteor/getCityRadar?province=hu-bei&city=${city}&token=yzqxj`
  ).then((res) => res.json());
}

export default {
  data() {
    return {
      visible: true,
      categoryList: [
        {
          city: "huazhong",
          name: "华中",
        },
        {
          city: "nan-tong",
          name: "南通",
        },
        {
          city: "yan-cheng",
          name: "盐城",
        },
        {
          city: "xu-zhou",
          name: "徐州",
        },
        {
          city: "lian-yun-gang",
          name: "连云港",
        },
        {
          city: "chang-zhou",
          name: "常州",
        },
        {
          city: "huai-an",
          name: "淮安清江浦区",
        },
        {
          city: "tai-zhou",
          name: "泰州",
        },
      ],
      activeCity: {
        city: "huazhong",
        name: "华中",
      },
      list: [],
      activeIndex: 0,
      isTime: true, // 是否正在记时
    };
  },

  mounted() {
    this.getData();
  },
  beforeDestroy() {
    this.stopTimer();
  },

  watch: {
    activeCity() {
      this.getData();
    },
  },

  methods: {
    getData() {
      if (this.activeCity.city === "huazhong") {
        fetchRadar({}).then((res) => {
          if (res && Array.isArray(res.info)) {
            this.list = res.info;
            this.setTimer();
          }
        });
      } else {
        fetchCityRadar({
          city: this.activeCity.city,
        }).then((res) => {
          if (res && Array.isArray(res.info)) {
            this.list = res.info;
            this.setTimer();
          }
        });
      }
    },
    setTimer() {
      this.stopTimer();
      this.setActiveIndex();
      this.isTime = true;
      this.timer = setInterval(() => {
        this.setActiveIndex();
      }, 1000);
    },
    stopTimer() {
      if (this.timer) {
        this.isTime = false;
        clearInterval(this.timer);
      }
    },
    setActiveIndex(index) {
      if (typeof index === "number") {
        this.activeIndex = index;
        return;
      }
      if (this.activeIndex < this.list.length - 1) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0;
      }
    },
    reduceActiveIndex() {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      }
    },
  },
};
</script>

<style scoped>
.category {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
}
.category div {
  cursor: pointer;
}
.category .active {
  font-weight: bold;
  color: #f09636;
}
.radar {
}
.radar .image {
  display: block;
  margin: 0 auto;
  /* width: 600px; */
  height: 60vh;
}
.iconfont {
  color: #fff;
}

.radar .footer {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radar .footer div {
  height: 20px;
  width: 20px;
  margin-right: 4px;
  border: 1px solid #999;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.radar .footer .active {
  background-color: var(--theme-color);
  border-color: var(--theme-color);
}
</style>