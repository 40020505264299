<template>
  <div>
    <Modal title="天气预报" :visible="visible" @close="visible = false">
      <div class="grid-panel">
        <div>
          <div class="title">
            <span>24小时预报</span>
            <span class="time">{{ updateTime }} 更新</span>
          </div>
          <div class="hours">
            <div
              v-for="(item, index) in today"
              :key="index"
              :class="isNow(item.hours) ? 'active' : ''"
            >
              <div style="margin-bottom: 24px">{{ item.win }}</div>
              <div style="margin-bottom: 24px">{{ item.win_speed }}</div>
              <img
                style="margin-bottom: 24px; display: flex"
                width="24"
                v-if="item.wea_img"
                :src="getImagePath(item.wea_img)"
                alt
              />
              <div style="margin-bottom: 12px">{{ item.tem }}℃</div>
              <div class="hour">{{ item.hours }}</div>
            </div>
          </div>
        </div>

        <div>
          <div class="title">
            <span>一周天气预报</span>
            <span class="time">{{ updateTime }} 更新</span>
          </div>

          <div style="height: 280px; overflow: hidden">
            <div class="weather" style="padding-top: 15px">
              <div v-for="(item, index) in filteredList" :key="index">
                <div style="margin-bottom: 14px">{{ item.dateStr }}</div>
                <div style="margin-bottom: 14px">{{ item.shortDate }}</div>
                <div class="image-wrapper">
                  <img :src="getImagePath(item.wea_day_img)" alt />
                </div>
              </div>
            </div>

            <Chart v-if="filteredList.length > 0" :list="filteredList" />

            <div class="weather" style="padding-bottom: 15px">
              <div v-for="(item, index) in filteredList" :key="index">
                <div class="quality">{{ item.air_level }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>


<script>
import Chart from "./components/chart.vue";
export default {
  components: {
    Chart,
  },

  data() {
    return {
      visible: true,
      updateTime: "",
      weatherList: [],
    };
  },
  computed: {
    today() {
      if (this.weatherList.length > 0) {
        const obj = this.weatherList[0];
        if (Array.isArray(obj.hours)) {
          return obj.hours.filter((item, index) => index % 3 === 0);
        } else {
          return [];
        }
      } else {
        return [];
      }
    },

    filteredList() {
      const now = new Date();
      let year = now.getFullYear();
      let month = now.getMonth() + 1;
      let date = now.getDate();
      if (month < 10) {
        month = "0" + month;
      }
      if (date < 10) {
        date = "0" + date;
      }

      return this.weatherList.map((item) => {
        let shortDate;
        if (typeof item.date === "string") {
          shortDate = item.date.substring(5);
        }

        let dateStr = "";
        if (item.date === `${year}-${month}-${date}`) {
          dateStr = "今天";
        } else {
          dateStr = item.week;
        }

        return {
          ...item,
          shortDate,
          dateStr,
        };
      });
    },
  },
  mounted() {
    this.getWeatherForeCast();
  },
  methods: {
    getWeatherForeCast() {
      fetch(
        "https://adm.ac.cn/Api/Weather/getForcast7?token=njszy&city=淮安清江浦区"
      )
        .then((res) => res.json())
        .then((res) => {
          if (res && res.info) {
            this.updateTime = res.info.update_time;
          }
          if (res && res.info && Array.isArray(res.info.data)) {
            this.weatherList = res.info.data;
          }
        });
    },

    getImagePath(name) {
      return `https://adm.ac.cn/static/img/weather/mango/${name}.png`;
    },

    isNow(hours) {
      let nowHours = new Date().getHours();
      if (nowHours < 10) {
        nowHours = "0" + nowHours;
      }
      return hours && hours.indexOf(nowHours) > -1;
    },
  },
};
</script>


<style lang="less" scoped>
.wrapper {
  height: 80vh;
  overflow-y: auto;
}
.grid-panel {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.title {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #353744;
  display: flex;
  align-items: center;
  position: relative;
  left: 1em;
  &::before {
    content: "";
    position: absolute;
    top: 0.5em;
    bottom: 0.3em;
    left: -1em;
    width: 6px;
    background-color: var(--theme-color);
  }
}

.time {
  font-size: 12px;
  padding-left: 34px;
}
.hours {
  height: 270px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  background-color: rgba(111, 131, 239, 0.1);
  padding: 0 20px;
  font-size: 12px;
  font-weight: 400;
  & > div {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hour {
    border-top: 1px solid var(--theme-color);
    padding-top: 12px;
    color: var(--theme-color);
  }

  .active {
    background-color: var(--theme-color);
    color: #fff;

    .hour {
      border-top: 1px solid #fff;
      color: #fff;
    }
  }
}

.weather {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  background-color: rgba(111, 131, 239, 0.1);

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-wrapper {
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
      }
    }

    .quality {
      background-color: var(--theme-color);
      padding: 0 8px;
      line-height: 1.5em;
      border-radius: 24px;
      font-size: 12px;
      color: #fff;
    }
  }
}
</style>
