<template>
  <div>
    <div class="title">
      <span>2小时雨量预报</span>
      <span class="time">{{ updateTime }} 更新</span>
    </div>
    <div id="rain-chart"></div>
  </div>
</template>
<script>
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([GridComponent, LineChart, CanvasRenderer]);

export default {
  data() {
    return {
      updateTime: "",
      list: [],
    };
  },

  mounted() {
    fetch(
      "https://adm.ac.cn/Api/Weather/getRainByLocation?token=njszy&lng=118.824&lat=32.051145"
    )
      .then((res) => res.json())
      .then((res) => {
        if (res && res.info) {
          this.updateTime = res.info.update_time;
        }
        if (res && res.info && Array.isArray(res.info.data)) {
          this.list = res.info.data;
          this.initChart(this.list);
        }
      });
  },

  methods: {
    initChart(list) {
      var chartDom = document.getElementById("rain-chart");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        grid: {
          right: 20,
          bottom: 40,
          left: 60,
          top: 40,
        },
        xAxis: {
          type: "category",
          data: list.map((item) => item.time),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: list.map((item) => item.value),
            type: "line",
            smooth: true,
          },
        ],
      };

      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="less" scoped>
.title {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
  color: #353744;
  display: flex;
  align-items: center;
  position: relative;
  left: 1em;
  &::before {
    content: "";
    position: absolute;
    top: 0.5em;
    bottom: 0.4em;
    left: -1em;
    width: 6px;
    background-color: var(--theme-color);
  }
}

.time {
  font-size: 12px;
  padding-left: 34px;
}

#rain-chart {
  background-color: rgba(111, 131, 239, 0.1);
  height: 300px;
  width: 100%;
  margin-bottom: 16px;
}
</style>