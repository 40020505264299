<template>
  <div
    id="weather-chart"
    style="
      height: 120px;
      width: 100%;
      background-color: rgba(111, 131, 239, 0.1);
    "
  ></div>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([GridComponent, LineChart, CanvasRenderer]);

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },

  mounted() {
    var chartDom = document.getElementById("weather-chart");
    this.chart = echarts.init(chartDom);

    const list = this.list;

    var option;

    option = {
      xAxis: {
        show: false,
        boundaryGap: false,
        axisLine: {
          show: false,
          lineStyle: {
            color: "transparent",
          },
        },

        splitLine: {
          lineStyle: {
            width: 0,
          },
        },
        type: "category",
        data: list.map((item) => {
          return {
            value: item.shortDate,
            textStyle: {
              //   color: "#fff",
            },
          };
        }),
      },
      grid: {
        left: "7%",
        right: "7%",
        top: "17%",
        bottom: 0,
      },
      yAxis: {
        show: false,
        splitLine: {
          show: false,
          lineStyle: {
            width: 0,
          },
        },
      },
      series: [
        {
          data: list.map((item) => item.tem1),
          type: "line",
          smooth: true,
          label: {
            show: true,
            // color: "#fff",
            formatter: `{c}°`,
          },
        },
        {
          data: list.map((item) => item.tem2),
          type: "line",
          smooth: true,
          label: {
            show: true,
            // color: "#fff",
            formatter: `{c}°`,
          },
        },
      ],
    };

    option &&
      this.chart &&
      this.chart.setOption &&
      this.chart.setOption(option);
  },
};
</script>